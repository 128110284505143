<template>	
	<div class="bind-mobile">
		<van-field
			readonly
			clickable
			name="picker"
			:value="area"
			label="地区"
			placeholder="点击选择城市"
			@click="showPicker = true"
			is-link
			center
		/>
		<van-field
			v-model="mobile"
			name="手机号"
			label="手机号"
			type="number"
			placeholder="请输入手机号"
			:rules="[{ required: true, message: '请填写正确的手机号' }]"
			center
			clearable
		/>
		<van-field
			v-model="sms"
			center
			clearable
			type="number"
			label="短信验证码"
			placeholder="请输入短信验证码">
			<template #button>
				<van-button v-if="smsSend" size="small" type="info" disabled class="bind-btn">{{seconds}}</van-button>
				<van-button v-else size="small" @click="sendSms" class="bind-btn" :loading="sendLoading">发送验证码</van-button>
			</template>
		</van-field>
		<div style="margin: 16px;">
			<van-button block type="info" @click="onSubmit" class="submit-btn" :loading="submitLoading">提交</van-button>
		</div>


		<van-popup v-model="showPicker" position="bottom">
			<van-picker
				show-toolbar
				:columns="columns"
				@confirm="onConfirm"
				@cancel="showPicker = false"
				class="column-css"
			/>
		</van-popup>
	</div>
</template>
<script>
	/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	import UTILS from '@/utils/common-utils';
	const AGENT_REQ_HANDLE = REQ_HANDLE.agent;
	const PASS_REQ_HANDLE = REQ_HANDLE.pass;
	export default {
		data: ()=> ({
			value: '',
			columns: ['中国大陆', '香港', '澳门', '台湾'],
			showPicker: false,
			sms: '',
			mobile: '',
			area: '中国大陆',
			smsSend: false,
			timer: null,
			seconds: 180,
			sendLoading: false,
			submitLoading: false,
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr
			}),
			...mapGetters({
				login: 'checkLogin',
			})
		},
		mounted() {
			// this.$store.commit('updatePageTitle',{title: '绑定手机_指尖货运小店'})
		},
		methods: {
			onConfirm(value) {
				this.area = value;
				this.showPicker = false;
			},
			async onSubmit(values) {
				console.log('submit', values);
				let t_phone={
					area: this.getAreaCode(),
					phone: this.mobile,
				}
				if(!this.area){
					this.$toast('请选择地区');
					return 
				}
				if(!this.mobile){
					this.$toast('请输入手机号');
					return 
				}else if(!UTILS.verifyMobile(t_phone)){
					this.$toast('手机号码有误，请仔细检查！');
					return 
				}
				if(this.sms.length < 6){
					this.$toast('请输入正确的验证码');
					return 
				}
				this.submitLoading=true
				let _this = this,
					data = {
						phone: this.mobile,
						area: this.getAreaCode(),
						code: this.sms
					},
					success = async ()=> {
						_this.submitLoading=false
						const toast = _this.$toast.success({
							duration: 0, // 持续展示 toast
							forbidClick: true,
							message: '^_^ 绑定成功, 即将跳转',
						});
						await _this.$store.dispatch('loginByPassToken')
						toast.clear()
						let redirectUrl = decodeURIComponent(_this.$route.query.redirectUrl)
						_this.$router.push(redirectUrl)
					},
					fail = (code, msg)=> {
						_this.submitLoading=false
						_this.$toast('绑定失败，请联系客服:' + (code? code : '')  + ','+ (msg ? msg : ''))
					}
				await this.$store.dispatch('bindMobilePhone', data).then(success, fail)
			},
			async sendSms() {
				let t_phone={
					area: this.getAreaCode(),
					phone: this.mobile,
				}
				if(!this.area){
					this.$toast('请选择地区');
					return 
				}
				if(!this.mobile){
					this.$toast('请输入手机号');
					return 
				}else if(!UTILS.verifyMobile(t_phone)){
					this.$toast('手机号码有误，请仔细检查！');
					return 
				}
				this.sendLoading=true
				let data = {
					phone: this.mobile,
					area: this.getAreaCode()
				}	
				REQ_HANDLE.pass.get({
					url:'/api-pass/v-code/agent-mall/custom/validate', 
					data
				}).then( ()=> {
					this.sendLoading=false
					this.smsSend = true
					let _this = this
					_this.seconds = 180
					this.timer = setInterval(()=> {
						_this.ticktock()
					}, 1000)
				}).catch(err=> {
					this.sendLoading=false
					this.$toast(err || '服务器错误，请联系客服')
				})
			},
			ticktock() {
				this.seconds = this.seconds - 1;
				if(this.seconds == 0) {
					this.clearTimer()
				}
			},
			clearTimer() {
				clearInterval(this.timer)
				this.smsSend = false
			},
			getAreaCode() {
				let area = this.area,
					res = '86'
				switch (area) {
					case '台湾':
						res = '886'
						break;
					case '香港':
						res = '851'
						break
					case '澳门':
						res = '853'
						break;
					default:
						break;
				}
				return res
			},

		}
	};
</script>
<style scoped >
@import "~@/assets/css/bindMobile.css";
</style>